.cardq{
    position: relative;
    background-color: rgb(77, 77, 77);
    width: 25%;
    height: 250px;
    border-radius: 10px;
    display: flex;
    padding: 10px 30px;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(233, 233, 233, 0.2);
  }
  
  .headingq {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
  }
  
  .paraq {
    text-align: center;
    color: #ffffff;
    opacity: 0.7;
    line-height: 1.4;
  }

  .overlayq {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(170, 170, 170, 0.6);
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  .cardq:hover .overlayq {
    opacity: 1;
    pointer-events: auto;
  }
  
  .cardq .cardq-btnq {
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 16px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
    border: none;
    opacity: 0;
    scale: 0;
    transform-origin: 0 0;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .cardq:hover .cardq-btnq {
    opacity: 1;
    scale: 1;
  }
  
  .cardq .cardq-btnq:hover {
    box-shadow: 0 0 0px 5px rgba(0, 0, 0, 0.3);
  }
  
  .cardq .cardq-btnq:active {
    scale: 0.95;
  }
  
  .overlayq::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 100%;
    height: 100%;
    background-image:#929292;
    transition: transform 0.5s ease;
  }
  
  .cardq:hover .overlayq::after {
    transform: translate(-50%, -50%) scale(2);
  }
  