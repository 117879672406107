@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

$mobile-breakpoint: 350px;
$large-mobile-breakpoint: 530px;
$tablet-breakpoint: 700px;
$desktop-breakpoint: 1200px;

@mixin horizontal-center {
  margin-left: auto;
  margin-right: auto;
}

@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin mobile {
  @media (min-width: #{$mobile-breakpoint}) and (max-width: #{$large-mobile-breakpoint - 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$large-mobile-breakpoint}) and (max-width: #{$tablet-breakpoint - 1}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-breakpoint}) and (max-width: #{$desktop-breakpoint}) {
    @content;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    top: -300px;
  }

  100% {
    top: 0;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.7);
  }

  95% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes reveal-info {
  0% {
    height: 0;
  }

  5% {
    height: 350px;
  }

  95% {
    height: 350px;
  }

  100% {
    height: 0;
  }
}

body {
  margin: 0;
  padding: 0;
}

.containeruy {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100vh; // Full screen height
  background-color: #000000;
  font-size: 14px;
  font-family: "Montserrat", sans;

  .container-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; // Center all content vertically and horizontally
    position: relative;
  }

  .main-headings {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; // Center the text horizontally
    z-index: 2; // Ensure the text is above the image

    .heading {
      margin-top: 10px;

      #heading1,
      #heading3 {
        margin: 0;
      }

      #heading1 {
        font-size: 3rem;
        font-weight: 400;
        letter-spacing: -2px;
      }

      #heading3 {
        margin-top: -10px;
        font-size: 4rem;
        font-weight: 800;
        letter-spacing: 2px;
      }
    }

    .short-bio {
      width: 80%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.2rem;
      line-height: 1.8;
      text-align: center; // Align text in the center
    }
  }

  .image-wrapper {
    flex-basis: 100%;
    height: 100vh; // Full screen height
    position: relative;

    .image-container {
      width: 100%;
      height: 100vh; // Full screen height
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        width: 100vw; // Full viewport width
        height: 100vh; // Full viewport height
        object-fit: cover; // Cover entire viewport without distorting aspect ratio
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; // Make sure the image stays below the text
      }
    }
  }
}

@include mobile {
  * {
    animation-name: none !important;
  }

  .container {
    width: 90%;
    height: 700px;
    font-size: 5px;
  }

  .container-inner {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .main-content,
  .image-wrapper {
    flex-basis: 100% !important;
  }

  .heading {
    margin-left: 55px !important;
  }

  #heading1,
  #heading2 {
    margin: 0;
    margin-top: -5px !important;
  }

  .short-bio {
    margin-top: -10px !important;
    margin-left: 60px !important;
  }

  .image-container {
    height: 400px;
  }

  img {
    width: 80% !important;
  }
}

@include tablet {
  * {
    animation-name: none !important;
  }

  .container {
    width: 90%;
    height: 900px;
    font-size: 8px;
  }

  .container-inner {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .main-content,
  .image-wrapper {
    flex-basis: 100% !important;
  }

  .image-wrapper {
    height: 400px;
  }

  img {
    width: 80% !important;
  }
}

@include desktop {
  .container {
    width: 100%;
    height: auto;
    font-size: 9px;
  }
}

.typing-text-container {
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing-text {
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid white; // Blinking caret
  display: inline-block;
  width: 0;
  animation: typing 3.5s steps(40, end) forwards, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: white;
  }
}