.profile-header {
  display: flex;
  /* background-color: #0ca1f7; */
  height: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: flex-start;
  background-image: url("../assets/gem-cover.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 25vh;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.profile-photo {
  display: block;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: rgb(71, 71, 71);
  margin: 0 auto;
  /* background-image: url("http://nickylew.com/wp-content/uploads/2017/05/nickylew-2017.jpg"); */
  background-size: 230%;
  background-position: 80% 0%;
  background-repeat: no-repeat;
}

.userprofile {
  background: #fff;
  display: flex;
  width: 100%;
  height: 75%;
  flex-direction: column;
  position: relative;
  font-family: Roboto, sans-serif;
} 
.firstnameandlastname {
  font-family: "Roboto", sans-serif;
  font-weight: bold; 
  font-size: 24px;
  color: #333; 
}

.Allbutton {
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  padding: 15px 20px;
  margin-top: 5px;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 40px;
  float: right;
  margin-left: 10px;
}
.Alllbutton {
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  padding: 15px 20px;
  margin-top: 5px;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 40px;
  float: right;
  align-items: center;
}
.lando_image {
  max-width: 100%; /* Make sure the image fits within its container */
  max-height: 100%;
}
.Auction-body {
  height: 150px; /* Set the desired image height */
  overflow: hidden;
}
.lando_image {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any unwanted space below the image */
}



/* Media query for screens smaller than 600px (adjust the value based on your needs) */
@media (max-width: 600px) {
  .Auction-body {
    height: auto; /* Allow the container to adjust its height based on content */
  }
}


.nameid {
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* Add this line to center-align the text */
}
.us {
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* Add this line to center-align the text */
}

.location {
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: center; /* Add this line to center-align the text */
}
.rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ffffff;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
}

.rating {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.ratingIcon {
  font-weight: bold;
  color: #333;
}
.gridItemRight {
  display: flex;
  justify-content: center; /* Align content to the right corner */
  align-items: center; /* Center content vertically */
  height: 100%; /* Ensure the container occupies full height */
}

.mail-button {
  align-items: center;
  margin-right: 10px; /* Add margin to the right to create horizontal spacing */
}
.ratingValue {
  color: #666;
}
/* Style for the mail-themed buttons */

.profile-image {
  width: 150px;
  height: 150px;
  border: 6px solid white;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 20px;
}

.edit-profile-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 20px;
}

.profile-info {
  text-align: center;
  margin-top: 20px;
}

.profile-info h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.profile-info p {
  font-size: 16px;
  color: #333;
}

.profile-stats {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.stat {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.stat-number {
  font-size: 20px;
  font-weight: bold;
}

.stat-label {
  margin-top: 5px;
}

.about-section {
  margin-top: 20px;
}

.about-section h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.about-section ul {
  list-style: none;
  padding: 0;
}

.about-section li {
  font-style: italic;
}

recent-photos h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-top: 20px;
}

.photo-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.photo {
  width: calc(50% - 5px);
  margin-bottom: 10px;
  border-radius: 5px;
}

.photo:hover {
  cursor: pointer;
  opacity: 0.7;
}

.firstnameandlastname {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.useremailtitle {
  font-size: 16px;
  font-weight: bold;
  color: green;
  margin-top: 10px;
}
.informationSection{
  text-align: center;
}
.username {
  padding: 0.25rem;
  text-align: right;
  color: rgb(221, 29, 4);
  font-weight: bold;
  font-size: 12px;
}

.auction-title1 {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}

.Auctioniitemr {
  width: 250px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.itemincontainer {
  flex-wrap: wrap;
  display: flex;
  margin: 0 -10px; /* Add negative margin to compensate for item margins */
  justify-content: center; /* Center items horizontally */
}
.grid-itemicontainerr {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
     

}
.Auction-itemincontainer:hover {
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.06);
}






/* Styles for smaller screens (e.g., mobile devices) */
@media (max-width: 767px) {
  .profile-header {
    height: 200px; /* Adjust height for smaller screens */
  }

  .profile-photo {
    width: 100px; /* Adjust width for smaller screens */
    height: 100px; /* Adjust height for smaller screens */
    margin: 10px auto; /* Add margin for spacing */
  }
  .auction-title1 {
    font-size: 20px; 
  margin-left: 25px;}


  .Allbutton, .Alllbutton {
    padding: 10px 15px;
    font-size: 14px;
    margin-left: 0;
    margin-top: 10px;
    float: none;
    align-items: center;
    margin-right: 0;
  }

  .gridItemRight {
    justify-content: center;
    align-items: center;
    height: auto;
  }

  
}