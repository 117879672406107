@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,800&display=swap");

.itemPage {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.itemcontainer {
  margin-top: 250px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
}

.itemcontainer .imgBx {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  width: 45%;
  height: 100%;
  background: #ffffff;
  transition: 0.3s linear;
  float: left;
}

.itemcontainer .imgBx:before {
  position: absolute;
  top: 0px;
  left: 24px;
  color: #000;
  opacity: 0.2;
  font-size: 8em;
  font-weight: 800;
}

.itemcontainer .imgBx img {
  position: relative;
  width: 700px;
  transform: rotate(-30deg);
  left: -50px;
  transition: 0.9s linear;
  float: left;
}

.itemcontainer .details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
  float: right;
}

.itemcontainer .details h2 {
  margin: 0;
  padding: 0;
  font-size: 2.4em;
  line-height: 1em;
  color: #444;
}
.itemcontainer .details h4 {
  margin: 0;
  padding: 0;
  font-size: 2.4em;
  line-height: 1em;
  color: #444;
}
.itemcontainer .startdate p {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  line-height: 1em;
  color: #444;
}
.itemcontainer h5 {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  line-height: 1em;
  color: #444;
}

.itemcontainer .details h2 span {
  font-size: 0.4em;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999;
}
.itemcontainer span {
  font-size: 10 em;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999;
}

.itemcontainer .details h3 {
  margin: 0;
  padding: 0;
  font-size: 2.5em;
  color: #a2a2a2;
  float: left;
}
.itemcontainer .details button {
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  padding: 15px 20px;
  margin-top: 5px;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 40px;
  float: right;
}

.product-colors span {
  width: 26px;
  height: 26px;
  top: 7px;
  margin-right: 12px;
  left: 10px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.black {
  background: #000;
}

.red {
  background: #d5212e;
}

.orange {
  background: #f18557;
}

.product-colors .active:after {
  content: "";
  width: 36px;
  height: 36px;
  border: 2px solid #000;
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box;
  left: -5px;
  top: -5px;
}

/* responsive */
@media (max-width: 1080px) {
  .itemcontainer {
    height: auto;
  }
  .itemcontainer .imgBx {
    padding: 40px;
    box-sizing: border-box;
    width: 100% !important;
    height: auto;
    text-align: center;
    overflow: hidden;
  }
  .itemcontainer .imgBx img {
    left: initial;
    max-width: 100%;
    transform: rotate(0deg);
  }
  .details {
    width: 100% !important;
    height: auto;
    padding: 20px;
  }
  .itemcontainer .details p {
    margin-left: 0;
    max-width: 100%;
  }
}




/* Button Styles */
.buttonito {
  background-color: #00c9ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}

.buttonito {
  background-color: #00c9ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tbody tr:hover {
  background-color: skyblue;
  cursor: pointer;
}

.buttonito:hover {
  background-color: #0099b0;
}
.itemPage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-family: "Work Sans";
}
.card {
  max-width: 1000px;
  width: 100%;
  padding: 4rem;
  background-color: rgb(46, 45, 45);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    padding: 1.5rem;
  }
}
.row {
  margin: 0;
}
.path {
  color: grey;
  margin-bottom: 1rem;
}
.path a {
  color: #ffffff;
}
.info {
  padding: 6vh 0vh;
}
@media (max-width: 768px) {
  .info {
    padding: 0;
  }
}
.checked {
  color: rgb(255, 217, 0);
  margin-right: 1vh;
}
.fa-star-half-full {
  color: rgb(255, 217, 0);
}
img {
  height: fit-content;
  width: 75%;
  padding: 1rem;
}
@media (max-width: 768px) {
  img {
    padding: 2.5rem 0;
  }
}
.title .col {
  padding: 0;
}
.fa-heart-o {
  font-size: 2rem;
  color: #ffffffaf;
  font-weight: lighter;
}
#reviews {
  margin-left: 3vh;
  color: grey;
}
.price {
  margin-top: 2rem;
}
label.radio span {
  padding: 1vh 4vh;
  background-color: rgb(54, 54, 54);
  color: grey;
  display: inline-block;
  margin-right: 2vh;
}
label.radio input:checked + span {
  border: 1px solid white;
  padding: 1vh 4vh;
  background-color: rgb(54, 54, 54);
  margin-right: 2vh;
  color: #ffffff;
  display: inline-block;
}
.carousel-control-prev {
  width: unset;
}
.carousel-control-next {
  left: 8vh;
  right: unset;
  width: unset;
}
.lower {
  margin-top: 3rem;
}
.lower i {
  padding: 2.5vh;
  margin-right: 1vh;
  color: grey;
  border: 1px solid rgb(85, 85, 85);
}
.lower .col {
  padding: 0;
}
@media (max-width: 768px) {
  .lower i {
    padding: 2vh;
    margin-right: 1vh;
    color: grey;
    border: 1px solid rgb(85, 85, 85);
  }
}
.btn {
  background-color: transparent;
  border-color: rgba(186, 216, 125, 0.863);
  color: rgba(186, 216, 125, 0.863);
  padding: 1.8vh 4.5vh;
  height: fit-content;
  border-radius: 3px;
}
.btn:focus {
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  transition: none;
}
.btn:hover {
  color: white;
}
@media (max-width: 768px) {
  .btn {
    background-color: transparent;
    border-color: rgba(186, 216, 125, 0.863);
    color: rgba(186, 216, 125, 0.863);
    padding: 1vh;
    font-size: 0.9rem;
    height: fit-content;
    border-radius: 3px;
  }
}
a {
  color: unset;
}
a:hover {
  color: unset;
  text-decoration: none;
}
label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}

.buttonitoReverse {
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}

.buttonitoReverse:hover {
  background-color: #b00000;
}

/* Modal Styles */
.headerito {
  background-color: #00c9ff;
  color: white;
  font-family: "Futura", sans-serif;
  font-size: 24px;
  padding: 10px;
}

.purchase_photo {
  width: 200px;
  height: 200px;
  margin: 20px auto;
  display: block;
}

/* TextField Styles */
#input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #00c9ff;
  border-radius: 5px;
  font-family: "Futura", sans-serif;
  font-size: 18px;
}

/* DataGrid Styles */
.MuiDataGrid-root {
  color: black;
}

/* DateTimePicker Styles */
.MuiInputBase-root {
  font-family: "Futura", sans-serif;
}

/* Map Styles */
#map {
  width: 100%;
  height: 300px;
  border: 1px solid #00c9ff;
  border-radius: 5px;
}

/* Assured Workload Icon Styles */
.AssuredWorkloadIcon {
  font-size: 100px;
  color: #00c9ff;
  margin-left: 216px;
}

/* Privacy Tip Icon Styles */
.PrivacyTipIcon {
  font-size: 100px;
  color: #00c9ff;
  margin-left: 216px;
}
@media only screen and (max-width: 959px) {
  .itemPage {
    font-size: 0.5rem;
  }

  .itemcontainer {
    flex-direction: column;
    width: 350px;
  }

  
  .itemcontainer .details h3 {
    margin: 0;
    padding: 0;
    font-size: 1.0em;
    color: #a2a2a2;
    float: left;
    

  }
  .itemcontainer .details {
    width: 50%; /* Full width for both image and details on smaller screens */
  }

  .itemcontainer .imgBx img {
    width: 100px;
    height: 100px; /* Make the image fill the container on smaller screens */
    transform: rotate(0deg); /* Reset the rotation */
    left: 0; /* Reset left position */
  }

  .itemcontainer .details {
    padding: 20px; /* Adjust padding for details on smaller screens */
  }

  /* Add more responsive styles as needed for other elements */
}

.cardtyls{
  margin-top: 500px;
  display: flex;
  justify-content: center;
}

.cardty {
  display: flex;
  position: relative;
  justify-content: center;
  overflow: hidden;
  text-align: left;
  border-radius: 0.5rem;
  max-width: 400px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color:  rgb(77, 77, 77);
  
}

.dismiss {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: black;
  border: 2px solid #D1D5DB;
  font-size: 1rem;
  font-weight: 300;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  transition: .3s ease;
}

.dismiss:hover {
  background-color: #ee0d0d;
  border: 2px solid #ee0d0d;
  color: #fff;
}

.headerty {
  padding: 1.25rem 1rem 1rem 1rem;
}

.contentty {
  margin-top: 0.75rem;
  text-align: center;
}

.titlety {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.messagety {
  margin-top: 0.5rem;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.actionsty {
  margin: 0.75rem 1rem;
}

.historyty {
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #1aa06d;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.trackty {
  display: inline-flex;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  color: #242525;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #D1D5DB;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

@keyframes animate {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.09);
  }
}