.messagecardform {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
.Messagecard {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
  }
  
  .Messagetitle {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .modal-container-header {
    padding: 16px 32px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .modal-container-title {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    font-weight: 700;
    font-size: 1.125;
  }
  
  .modal-container-title svg {
    width: 32px;
    height: 32px;
    color: #750550;
  }
  .icon-button {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.15s ease;
  }
  .Messageform {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .Messagegroup {
    position: relative;
  }
  
  .Messageform .Messagegroup label {
    font-size: 14px;
    color: rgb(99, 102, 102);
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    transition: all .3s ease;
  }
  
  .Messageform .Messagegroup input,
  .Messageform .Messagegroup textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    outline: 0;
    width: 600px;
    background-color: transparent;
  }
  
  .Messageform .Messagegroup input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
    top: 10px;
    background-color: transparent;
  }
  
  .Messageform .Messagegroup input:focus,
  .Messageform .Messagegroup textarea:focus {
    border-color: #3366cc;
  }
  
  .Messageform .Messagegroup input:focus+ label, .form .group textarea:focus +label {
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: #3366cc;
    font-weight: 600;
    font-size: 14px;
  }
  
  .Messageform .Messagegroup textarea {
    resize: none;
    height: 100px;
  }
  
  .Messageform button {
    background-color: #3366cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .Messageform button:hover {
    background-color: #27408b;
  }
  
  