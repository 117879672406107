/* Styling for the CreateCategory component */
.createItemPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  /* background-image: url("https://backgem.tfdatamaster.com/images/background.png"); */
  background-repeat: repeat;
  padding: 20px;
  border-radius: 10px;
}

.formContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#outlined-multiline-static {
  width: 100%;
}

.buttonito {
  display: inline-block;
  background-color: #007acc;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.buttonito:hover {
  background-color: #005b9f;
}

/* Styling for the title and category name input */

/* Styling for the category selection */
.buttonito {
  display: inline-block;
  background-color: #007acc;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.buttonito:hover {
  background-color: #005b9f;
}
