.mailinbox {
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  align-items: center;
}

.mail-list {
  width: 900px;
  padding: 6px;
  gap: 5px;
  margin-top: 10px;
  margin-left: 10px;
}

.message-card {
  margin-left: 50px;
}

@media only screen and (max-width: 959px) {
  .mail-list {
    width: 350px;
    padding: 6px;
    gap: 5px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .message-card {
    margin-left: 50px;
    font-size: 15px;
  }
  .pagenavigation {
    display: flex;
    margin-left: 125px;
  }

}
