/* Larger screens (desktops and laptops) */
.login-container {
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.reg{
  padding-top: 150px;


}
.ptag{
text-align: center;
}

/* Smaller screens (tablets and mobile devices) */
@media (max-width: 600px) {
  .login-container {
    padding-top: 50px;
    padding: 20px; /* Add some spacing for smaller screens */
  }

  .login-content {
    max-width: 90%; /* Adjust the max width for smaller screens */
  }

  .reg {
    padding: 10px;
    padding-top: 150px;
    align-items: center;
    /* Adjust padding as needed */
  }

  .ptag {
    text-align: center;
  }
}



