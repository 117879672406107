.mailcontainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.toggleButtonGroup {
  display: flex;
}

.toggleButton {
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-family: "Futura", sans-serif;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.toggleButton:hover {
  background-color: #f0f0f0;
}

.icon {
  margin-right: 5px;
}

@media only screen and (max-width: 959px) {
  .mailcontainer h4{
    font-size: 9px; 

}
  

}