/* PhotoCarousel.css */

.carousel {
  height: 400px;
  position: relative;
}

.imgUnclick {
  pointer-events: none;
  user-select: none;
}

.carouselInner {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  position: relative;
}

.carouselInner .left,
.carouselInner .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.carouselInner .left {
  left: 0;
}

.carouselInner .right {
  right: 0;
}

.carouselInner .center {
  flex: 86%;
  width: 520px;
  height: 300px;
  display: grid;
  place-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  text-align-last: center;
}

.carouselInner .arrow-back,
.carouselInner .arrow-forward {
  font-size: 30px;
  color: black;
}