/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

/* Forms container */
.forms-container {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
}

/* Sign-in and sign-up container */
.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

/* Form styles */
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

/* Sign-up form opacity */
form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

/* Input field styles */
.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 45px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 0.75rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 0.75rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

/* Social text styles */
.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

/* Social media styles */
.social-media {
  display: flex;
  justify-content: center;
}

/* Social icon styles */
.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

/* Button styles */
.btnrm {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btnrm:hover {
  background-color: #4d84e2;
}

/* Panels container */
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

/* Image styles */
.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

/* Panel styles */
.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space around;
  text-align: center;
  z-index: 6;
}

/* Left panel styles */
.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

/* Right panel styles */
.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

/* Content styles within the panels */
.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

/* Transparent button styles */
.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

/* Right panel image and content styles */
.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* Smaller image size on mobile */
.image {
  width: 200px;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

/* Content styles within the panels */
.panel .content {
  padding-right: 15%;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.8s;
}

.panel h3 {
  font-size: 1.2rem;
}

.panel p {
  font-size: 0.7rem;
  padding: 0.5rem 0;
}

/* Transparent button styles */
.btnrm.transparent {
  width: 110px;
  height: 35px;
  font-size: 0.7rem;
}

/* Mobile view styles (you can add more styles here) */
@media (max-width: 768px) {
  .forms-container {
    height: 100%;
  }

  .signin-signup {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
  }

  form {
    padding: 0rem 2rem;
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateX(0);
  }
}
