/* PAGINATION STYLING FOR MAIL*/

.paginationButtonsMail {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationButtonsMail a {
  padding: 10px;
  margin: 2px;
  border-radius: 5px;
  border: 1px solid teal;
  color: teal;
  cursor: pointer;
  background-color: white;
}

.paginationButtonsMail a:hover {
  color: white;
  background-color: teal;
}

.paginationActiveMail a {
  color: white;
  background-color: teal;
}
.itemperpage{
  margin-left: 25px;
display: flex;
width: 450px;
}
.paginationDisabledMail a {
  color: rgb(212, 235, 235);
  border: 1px solid rgb(207, 230, 230);
  /* background-color: white; */
}

.paginationDisabledMail a:hover {
  color: white;
  border: 1px solid white;
  background-color: rgb(207, 230, 230);
}
  
/* Message Button */

.buttonitoMail {
  margin-top: 15px;
  font-family: Futura;
  height: 40px;
  border: none;
  background-color: rgb(80, 211, 211);
  border-radius: 5px;
  color: white;
}

.buttonitoMail:hover {
cursor: pointer;
background-color: teal;
}
.cd{
  margin-left: 10px;
}
.buttonitoInfoMail {
  margin-top: 15px;
  font-family: Futura;
  height: 40px;
  border: none;
  background-color: rgb(159, 183, 183);
  border-radius: 5px;
  color: white;
}

.buttonitoInfoMail:hover {
cursor: pointer;
background-color: rgb(73, 79, 79);
}