.background-image-container {
  background-image: url("../assets/gem-cover.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* Ensure the background covers the full height */
  padding: 20px;
}

.adminusercard {
  max-width: 400px;
  border-radius: 1rem;
  border-color: #fff;
  background-color: rgba(31, 41, 55, 1);
  padding: 1rem;
}

.adminuserinfos {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.adminuserimage {
  height: 7rem;
  width: 7rem;
  border-radius: 0.5rem;
  background-color: rgb(118, 36, 194);
  background: linear-gradient(to bottom right, rgb(118, 36, 194), rgb(185, 128, 240));
}

.adminuserinfo {
  height: 7rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.adminusername {
  font-size: 1.25rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.adminuserfunction {
  font-size: 0.75rem;
  color: rgba(156, 163, 175, 1);
}

.adminuserrequest {
  margin-top: 1.5rem;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.adminuserrequest:hover {
  background-color: rgb(118, 36, 194);
  color: #fff;
}

.userscontainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  /* Reduced the gap between cards */
  margin-top: 20px;
}

.paginationButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style: none;
  padding: 0;
}

.paginationButtons li {
  margin: 0 5px;
}

.paginationButtons a {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.paginationButtons a:hover {
  background-color: #6c63ff;
  /* Highlight color */
  color: white;
}

.paginationActive a {
  background-color: #6c63ff;
  /* Active page button color */
  color: white;
}

.paginationDisabled a {
  cursor: not-allowed;
  opacity: 0.6;
}