.carousel .slide {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .carousel .slide.selected {
    opacity: 1;
  }
  
  .fade {
    animation: fadeIn 1.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Add a class to set the desired width and height */
  .carousel-image {
    width: 100%;         /* Full width */
    height: 500px;        /* Dynamic height based on the viewport height */
    object-fit: cover;   /* Ensure images cover the container without distortion */
  }
  