/* Full CSS code for Auctions page */

/* Title styling */
.title {
  font-size: 24px;
  margin-top: 10px;
  text-align: center;
}

/* Reset. */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Container for Auctions with background image */
.auctions-container {
  background-image: url("../../assets/gem-item-cover.jpg");
  /* Path to the image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* Ensures the background covers the entire height */
  padding: 20px;
  /* Add padding if needed */
}

/* Auction item styling */
.Auctioniitem {
  background-color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 250px;
  /* Adjust the width as needed */
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin: 15px;
  /* Add space between auction items */
  transition: transform 0.3s ease;
  /* Smooth hover effect */
}

.Auctioniitem:hover {
  transform: translateY(-5px);
  /* Slight lift on hover */
}

.Auctioniitem .name {
  font-weight: 600;
  font-size: 25px;
  text-align: start;
}

.Auction-body {
  height: 150px;
  /* Set the desired image height */
  overflow: hidden;
}

.lando_image {
  width: 170px;
  height: 170px;
  margin-inline: auto;
}

/* footer and additional info */
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Pagination styling */
.paginationButtons {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.paginationButtons a {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  text-decoration: none;
  color: #333;
}

.paginationButtons a:hover {
  background-color: #f0f0f0;
}

.paginationActive a {
  background-color: #14b6e3;
  color: #fff;
}

/* Previous and Next button styling */
.previousButton,
.nextButton {
  font-size: 16px;
}

/* Disabled pagination button styling */
.paginationDisabled {
  pointer-events: none;
  color: #ccc;
}

/* No results found styling */
.no-results {
  text-align: center;
  margin-top: 20px;
}

.header-text {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}

/* Grid container for auction items */
.grid-itemicontainerr {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
  /* Adjust the gap as needed */
  margin: 0 auto;
  /* Center the grid */
}

.pagenavigation {
  margin-top: 25px;
}

/* Responsive adjustments */
@media only screen and (max-width: 960px) {
  .Auction-body {
    width: 200px;
    height: 200px;
    /* Set the desired image height */
  }

  .Auctioniitem {
    width: 200px;
    /* Adjust the width as needed */
    margin: 10px;
    /* Add spacing between items */
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
  }

  .Auctioniitem .name {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .lando_image {
    max-width: 100%;
    /* Make sure the image fits within its container */
    max-height: 100%;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

/* Items per page select styling */
.items-per-page {
  margin-top: 20px;
}

.items-per-page label {
  font-size: 16px;
}

.items-per-page select {
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
}