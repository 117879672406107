/* Main container with background image */
.Procontainer {
  padding: 40px 20px;
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;
  background-image: url("../assets/gem-cover2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

/* Profile card style with solid background */
.profile-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  text-align: left;
  display: inline-block;
}

/* Profile photo */
.profile-photo {
  display: block;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: rgb(71, 71, 71);
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Profile name */
.profileName {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-top: 15px;
  text-align: center;
}

/* Flex container for approved icon and username */
.nameapproved {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.approvedIcon {
  color: green;
  font-size: 20px;
  margin-left: 8px;
}

.notApprovedIcon {
  color: red;
  font-size: 20px;
  margin-left: 8px;
}

/* User information title */
.userInfoTitle {
  font-size: 20px;
  color: #333;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

/* User information headers */
.userInfoHeader {
  font-size: 18px;
  color: #555;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.locationIcon {
  font-size: 20px;
  color: #007acc;
  margin-right: 8px;
}

.ratingIcon {
  font-size: 20px;
  color: #ff9900;
  margin-right: 8px;
}

/* General search input styling */
.searchInputs {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.info-item span {
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.location-text {
  font-size: 16px;
  margin-left: 8px;
  color: #333;
}

.seller-info,
.bidder-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.rating {
  margin-left: 8px;
  color: #333;
}

/* Auction item card */
.Auctioniitem {
  width: 250px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.Auctioniitem:hover {
  background-color: #f9f9f9;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}

.Auctioniitem .name {
  font-weight: bold;
  font-size: 18px;
}

.Auctioniitem .Auction-body {
  margin-top: 10px;
}

.Auctioniitem .footer {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

/* Pagination */
.paginationButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paginationButtons a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  text-decoration: none;
  color: #333;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.paginationButtons a:hover {
  background-color: #007acc;
  color: #fff;
}

.paginationActive a {
  background-color: #14b6e3;
  color: white;
}

.paginationDisabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Modal styling */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

  /* New styles to center the modal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #14b6e3;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background-color: #007acc;
}

/* Styles for larger screens */
@media (min-width: 768px) {
  .Procontainer {
    max-width: 1200px;
  }
}

/* Styles for smaller screens */
@media (max-width: 767px) {
  .Procontainer {
    padding: 20px;
    max-width: 100%;
    background-color: #14b6e3;
  }

  .profile-photo {
    width: 100px;
    height: 100px;
  }

  .profileName {
    font-size: 22px;
    margin-top: 10px;
  }

  .info-item span {
    margin-left: 0;
    margin-top: 5px;
  }

  .Auctioniitem {
    width: 100%;
    margin-bottom: 20px;
  }
}