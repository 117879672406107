/* Container Styles */

/* Page Styles */
body {
  background-repeat: repeat;
  min-height: 90vh;
  width: 100%;
}


/* Download Button Styles */
.download-button {
  background-color: #00c9ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  text-align: center;
}

.download-button:hover {
  background-color: #0099b0;
}

/* DataGrid Styles */
.MuiDataGrid-root {
  color: black;
}

/* DataGrid Toolbar Styles */
.MuiToolbar-root {
  background-color: #00c9ff;
}

.MuiToolbar-icon {
  color: white;
}

/* DataGrid Toolbar Button Styles */
.MuiButton-textPrimary {
  color: white;
}

.MuiButton-textPrimary:hover {
  background-color: #0099b0;
}

/* DataGrid Header Styles */
.MuiDataGrid-colCell {
  background-color: #00c9ff;
  color: white;
  font-size: 16px;
  font-family: "Futura", sans-serif;
}

/* DataGrid Row Styles */
.MuiDataGrid-row {
  &:hover {
    background-color: #e6f7ff;
  }
}
