.custom-box {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  margin: 8px;
  background-color: grey.50; /* Define your background color */
  color: grey.800; /* Define your text color */
  border-radius: 4px;
  text-align: center;
}

.custom-text {
  font-family: Futura; /* Use the desired font family */
  font-size: 14px;
}
