.searchInputs {
  display: flex;
  align-items: center;
}

.search input {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  background-color: #cfcece;
  border: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  height: 30px;
  width: 900px;
  display: flex;
}

.search input::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
}

.searchIcon {
  height: 60px;
  width: 50px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #000000;
  color: rgb(0, 0, 0);
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.searchResult {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  width: 900px;
  height: 245px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  align-items: center;

}

.searchResult::-webkit-scrollbar {
  display: none;
}

.searchResult p {
margin-left: 25px;}

.searchResult .searchItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.inorder {
  font-family: Futura, Avenir, Helvetica, sans-serif;
  font-size: 29px;
  margin-top: 10px;
  margin-left: 20px;
  color: #39d2f9;
}

.searchItem p {
  text-decoration: none;
}

p:hover {
  text-decoration: underline;
  cursor: pointer;
}

#clearBtn {
  cursor: pointer;
}

.filter{
  
}
