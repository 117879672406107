/* Landing container */
.landing-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  font-family: "Futura", sans-serif;
  background-color: #000000;
  color: #ffffff;
}

/* Banner Container */
.banner-container {
  background-color: rgba(0, 0, 0, 0.6);
  background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)),
    url('../../assets/gem-cover.jpg');
  /* Path to your image */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  min-height: 100vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* footer Styling */
.footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  /* Same as Tailwind's justify-between */
  align-items: flex-start;
  /* Aligns items to the start of the container */
  width: 100%;
  /* Ensures the div takes up the full width */
}

.footer-content div {
  flex: 1;
  /* Makes sure both divs take equal space */
}

.footer-links {
  text-align: right;
  /* Aligns the footer links to the right */
}

.footer-links a {
  margin-left: 20px;
  /* Adds spacing between links */
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #f0a500;
}

.footer-copy {
  margin-top: 20px;
  font-size: 0.9rem;
  opacity: 0.7;
}

/* Sliders */
.slider2fr,
.slider2f {
  display: flex;
  justify-content: center;
}

/* Header Content */
.header-content {
  text-align: center;
  padding: 20px;
  color: #000000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Card Container */
.card-containerf {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Custom App Bar */
.custom-app-bar {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Header Styles */
.h1-header {
  font-size: 2rem;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
}

.h4-header {
  text-align: center;
  padding: 20px;
  color: #fff;
  border-radius: 8px;
}

/* To center the header vertically on the page */
.app-bar-title {
  font-size: 2rem;
  margin: 0;
  padding-left: 20px;
  width: 100%;
  text-align: left;
}

.exit-button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #fff;
  margin: 0;
  padding-right: 20px;
  cursor: pointer;
}

/* Main Content */
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .landing-container {
    background-attachment: scroll;
  }

  .banner-container {
    background-size: cover;
    min-height: 50vh;
    height: auto;
  }

  .footer-content {
    flex-direction: column;
    /* Stack the footer sections on smaller screens */
    text-align: center;
  }

  .footer-links {
    text-align: center;
    margin-top: 20px;
    /* Add spacing between contact and links */
  }

  .footer-content div {
    flex: unset;
  }

  .footer-content h2 {
    font-size: 1.5rem;
  }

  .footer-copy {
    font-size: 0.8rem;
  }

  .h1-header {
    font-size: 1.5rem;
  }

  .h4-header {
    font-size: 1rem;
  }

  .header-content {
    padding: 10px;
  }

  .card-containerf {
    flex-direction: column;
  }

  .exit-button {
    font-size: 0.9rem;
    padding: 5px 10px;
  }

  .app-bar-title {
    font-size: 1.5rem;
    padding-left: 10px;
  }
}