.createitempage {
  display: flex;
  justify-content: center;
  background-image: url("../assets/gem-item-cover.jpg");

  /* Resizing the background image */
  background-size: cover;
  /* Makes sure the image covers the entire container */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  width: 100%;
  /* Ensure the container takes the full width */
  min-height: 100vh;
  /* Ensures the container has a full viewport height */
}

.form-container {
  width: 1000px;
  height: 1000px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 30px;
}

.formtitle {
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin: 10px 0 30px 0;
  font-size: 28px;
  font-weight: 800;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 15px;
}

.input {
  border-radius: 20px;
  border: 1px solid #c0c0c0;
  outline: 0 !important;
  box-sizing: border-box;
  padding: 12px 15px;
  width: 800px;
}

.outerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.formt-btn {
  padding: 10px 15px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: teal;
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 350px;
  height: 45px;
}

.formt-btn:active {
  box-shadow: none;
}