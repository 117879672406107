/* Style for the Categories component */
.tree-container {
  width: 100%;
}

/* Style for the TreeView and TreeItem */
.tree-view {
  width: 100%;
}

.tree-item {
  padding: 8px;
  cursor: pointer;
}

/* Style for selected TreeItem */
.tree-item-selected {
  background-color: #e0e0e0; /* Change the background color for selected items */
}

/* Style for expanded TreeItem */
.tree-item-expanded {
  font-weight: bold;
  color: #007acc; /* Change the color for expanded items */
}

/* Style for icons in TreeItem */
.tree-item-icon {
  font-size: 20px;
  margin-right: 8px;
}
